
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "CreateUser",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "Upload User",
        url: "user/create"
      },
      {
        name: "Form Option",
        url: "user/create"
      },
    ]);
    const levels = reactive(["JS1", "JS2", "JS3", "SS1", "SS2", "SS3", "STF"]);
    const  statuses = reactive(["student", "staff"]);
    const user = reactive({
      displayName: "John Doe",
      phoneNumber: "+123456789",
      email: "john.doe@email.com",
      password: "********",
      confirm_password: "********",
      reg_id: "0020239",
      level: "",
      status: ""
    });

    const name = ref("User");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, user, levels, statuses };
  },
});
